import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IBackendNotification, Notification } from '../../models/notification.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

type ResponseNotification = {
	status:string, 
	data: IBackendNotification[]
};

type ResponseReadNotification = {
	status:string; 
	unread_notifications: number;
};


@Injectable({
	providedIn: 'root'
})
export class NotificationService {
	readonly keys = {
		NOTIFICATIONS: 'LU_NOTIFICATIONS', 
	}; 
	public unreadNotifications = new BehaviorSubject<number>(null);

	constructor(
		private _http: HttpClient
	) {}

	getNotifications(){
		return this._http.get<ResponseNotification>(environment.apiUrl + `notifications`)
			.pipe(map((data: ResponseNotification) => {
				return data.data.map((elementData: IBackendNotification ) => new Notification().parse(elementData));
			})); 
	}

	readNotification(id: number){
		return this._http.put<ResponseReadNotification>(environment.apiUrl + `notifications/${id}`, id )
			.pipe(map((data: ResponseReadNotification) => data.unread_notifications));
	}


}
