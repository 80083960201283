import moment from 'moment';
import { IUser, IBackendUser, User } from './user.model';

export interface IBackendNotification {
	id:                        number;
	is_read:                   number;
	user_id:                   number;
	requirement_id:            number;
	university_requirement_id: number;
	message:                   string;
	type:                      string;
	created_at:                Date;
	updated_at:                Date;
	user:                      IBackendUser;
}

export interface INotification {
	id:                        number;
	isRead:                    number;
	userId:                    number;
	requirementId:             number;
	universityRequirementId:   number;
	message:                   string;
	type:                      string;
	createdAt:                 Date;
	updatedAt:                 Date;
	user:                      IUser;
}

export class Notification implements INotification {
	static readonly clean = Object.freeze(new Notification());
	id = 0;
	isRead = 0;
	userId = 0;
	requirementId = 0;
	universityRequirementId = 0;
	type = '';
	message = '';
	createdAt = new Date();
	updatedAt = new Date();
	user = new User();


	parse(obj: IBackendNotification) {
		this.id = Number(obj.id || Notification.clean.id);
		this.isRead = Number(obj.is_read || Notification.clean.isRead);
		this.userId = Number(obj.user_id || Notification.clean.userId);
		this.requirementId = Number(obj.requirement_id || Notification.clean.requirementId);
		this.universityRequirementId = Number(obj.university_requirement_id || Notification.clean.universityRequirementId);
		this.type = String(obj.type || Notification.clean.type);
		this.message = String(obj.message || Notification.clean.message);
		this.createdAt = obj.created_at ? moment(obj.created_at ).toDate() : Notification.clean.createdAt;
		this.updatedAt = obj.updated_at ? moment(obj.updated_at ).toDate() : Notification.clean.updatedAt;
		this.user = new User().parse(obj.user) || Notification.clean.user;
		return this;
	}

	set(obj: INotification) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = Notification.clean[key];
		});

		return this;
	}

	clone() {
		return new Notification().set(this);
	}
}
